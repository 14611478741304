import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import './index.css'

export default function Hankersnest() {
    const openGpay = (amt) => {
        let url = "upi://pay?"

        //pa
        url += "pa=" + encodeURI('hankersnest@sbi') + "&"

        //pn
        // eslint-disable-next-line
        url += "pn=" + 'fees' + "&"

        //cu
        url += "cu=INR&"

        //am
        url += "am=" + amt + "&"

        //tr
        url += "tr=" + Date.now() + "&"

        url += "url=" + encodeURI('http://gpay.hankersnest.com/a.html')

        console.log(url)

        window.open(url, "_self")
    }
    const sendMsg = async () => {
        var form = document.getElementById('contactForm')
        if (form.checkValidity() === true) {
            document.getElementById("btn").classList.add("disabled")
            document.getElementById("btn").innerHTML = `<span id="sp" class="spinner-border spinner-border-sm hide" role="status" aria-hidden="true"></span> Loading... `

            const name = document.getElementById("name").value
            const email = document.getElementById("email").value
            const subject = encodeURI(document.getElementById("subject").value + ":" + document.getElementById("phone").value)

            const message = encodeURI(document.getElementById("message").value)
            const url = `https://mail.hankersnest.com/WebForm2.aspx?name=${name}&email=${email}&title=${subject}&body=${message}`
            try {
                const response = await fetch(url, { mode: 'no-cors' })
                const data = await response.text()
                console.log(data)
            } catch (e) {
                console.log(e.message)
                return false
            }

            document.getElementById("btn").classList.remove("disabled")
            document.getElementById("btn").innerHTML = `Send Message`
            document.getElementById("dmsg").innerHTML = "Sent. You will soon be contacted!"
            document.getElementById("dmsg").classList.add("text-success")
            setTimeout(() => {
                document.getElementById("dmsg").innerHTML = ""
            }, 5000)
            return false
        }
    }
    const showalert = () => {
        alert('You need to have a UPI app installed. Please try in Mobile!!')
    }

    const handleNav = () => {
        document.getElementById('')
    }

    useEffect(() => {

    }, [])
    return (
        <>
            <div
                className="modal fade"
                id="PrivacyPolicy"
                tabIndex={-1}
                aria-labelledby="PrivacyPolicyHeading"
                aria-hidden="true"
            >
                <div className="modal-dialog modal-xl modal-dialog-scrollable">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h1 className="modal-title fs-5" id="PrivacyPolicyHeading">
                                Privacy Policy
                            </h1>
                            <button
                                type="button"
                                className="btn-close"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                            />
                        </div>
                        <div className="modal-body">
                            <p>
                                Welcome to HankersNest. Your privacy is very important to
                                us.HankersNest.com ('website','Web Site','site' ’mobile app’,’app’,
                                'we','us','our') is committed to protecting your privacy. The
                                Company respects and protects its Users privacy rights. This Privacy
                                Policy also explains the Users’ rights regarding their Personal
                                Information.
                            </p>
                            <br />
                            <p>
                                That is why we insist upon the highest standards for secure
                                transactions and customer (you,your,user) information privacy.
                                PLEASE READ THE FOLLOWING PRIVACY POLICY and USER TERMS AND
                                CONDITIONS CAREFULLY BEFORE USING THIS WEBSITE.
                            </p>
                            <br />
                            <p>
                                We store and process your information, such as Address Information
                                including your mobile number along with financial data if any, using
                                stringent security measures under the Information Technology Act,
                                2000. As a visitor to our site or mobile application you are advised
                                to please read the Privacy Policy carefully.&nbsp;
                            </p>
                            <br />
                            <p>
                                By accessing the services provided by HankersNest, you agree to the
                                collection and use of your data in the manner provided in this
                                Privacy Policy. If you have any objections to your information being
                                stored and processed, please do not use our Website and mobile app.
                                <br />
                                &nbsp;
                                <br />
                                <strong>
                                    The information provided by the user herein may be used by the
                                    Company and its affiliates:
                                </strong>
                            </p>
                            <br />
                            <p>
                                •&nbsp;&nbsp; &nbsp;To send communication regarding the status of
                                orders, offers, discounts, upcoming events, advertisements, relevant
                                policies, terms, conditions, feedback.
                                <br />
                                <br />
                                •&nbsp;&nbsp; &nbsp;For internal purposes such as auditing, data
                                analysis, and research to improve services, and customer
                                communication.
                            </p>
                            <br />
                            <p>
                                By accessing this Web Site or the Mobile App you agree that
                                HankersNest.com will not be liable for any direct or indirect or
                                consequential loss, damages or compensation arising from the use of
                                the information and material contained in this Web Site or from your
                                access of other material on the internet via web links for this Web
                                Site or the Mobile App. &nbsp;
                            </p>
                            <br />
                            <p>
                                This Android app needs SMS read permission to automatically read and
                                use OTP to authenticate mobile phone number, the user can deny for
                                this permission if they don't want to grant SMS read permission.
                            </p>
                            <br />
                            <p>
                                This Android app needs contact permission to access customers'
                                contact list to be able to share mobile app on behalf of the
                                customer when they wish to share the HankersNest with their friends,
                                the user can deny for this permission if they don't want to grant
                                contact permission.
                            </p>
                            <br />
                            <p>
                                This Android app needs location permission to be able to tracks
                                customer's location for Delivery purposes, the user can deny for
                                this permission if they don't want to grant location permission.
                            </p>
                            <br />
                            <p>
                                By using or accessing the Application the Users agree to the terms
                                and conditions of this Privacy Policy. The Users also expressly
                                consent to the Company’s use and disclosure of their Personal
                                Information in any manner as described in this Privacy Policy.
                            </p>
                            <br />
                            <p>
                                In the Legal Proceedings Company is required to respond to any legal
                                proceedings, including any legal notices or court orders, User
                                Personal Information may be disclosed pursuant to such legal
                                proceedings.
                            </p>
                            <br />
                            <p>
                                We reserve exclusive rights to modify, amend any condition in this
                                document which will be informed to you via the mobile app. &nbsp;
                            </p>
                            <br />
                        </div>
                        <div className="modal-footer">
                            <button
                                type="button"
                                className="btn btn-secondary"
                                data-bs-dismiss="modal"
                            >
                                Close
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div
                className="modal fade"
                id="Refund"
                tabIndex={-1}
                aria-labelledby="RefundHeading"
                aria-hidden="true"
            >
                <div className="modal-dialog modal-xl modal-dialog-scrollable">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h1 className="modal-title fs-5" id="RefundHeading">
                                Cancellation, Return &amp Refunds Policy
                            </h1>
                            <button
                                type="button"
                                className="btn-close"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                            />
                        </div>
                        <div className="modal-body">
                            <p>
                                We reserve the rights to cancel your order at any given point of
                                time.
                            </p>
                            <br />
                            <p>
                                <strong>Cancellation by HankersNest</strong>
                            </p>
                            <br />
                            1: We may cancel your enrollment in any course by providing you a
                            written notice 2 days in advance.
                            <br />
                            <br />
                            2: We may terminate the enrollment of any user if we find that the
                            user is not maintaining the required discpline or is involved in
                            illegal activities.
                            <br />
                            <br />
                            <p>
                                <strong>Cancellation by You</strong>
                            </p>
                            <br />
                            1: You can cancel any enrollment with a notice of a month in advance.
                            <br />
                            <br />
                            2: As fees is taken on monthly basis, in case of cancellation the
                            student will be allowed to continue to study till duration of the fees
                            he has paid.
                            <br />
                            <br />
                            3: For all digital products no refunds will be entertained.
                            <br />
                            <br />
                            <p>
                                <strong>Return &amp Refund Policy</strong>
                            </p>
                            <br />
                            1: Since we deal in digital products, once enrolled and the payment is
                            made, it cannot be refunded under any circumstances.
                            <br />
                            <br />
                        </div>
                        <div className="modal-footer">
                            <button
                                type="button"
                                className="btn btn-secondary"
                                data-bs-dismiss="modal"
                            >
                                Close
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div
                className="modal fade"
                id="TearmsAndConditions"
                tabIndex={-1}
                aria-labelledby="TearmsAndConditionsHeading"
                aria-hidden="true"
            >
                <div className="modal-dialog modal-xl modal-dialog-scrollable">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h1 className="modal-title fs-5" id="TearmsAndConditionsHeading">
                                Terms &amp Conditions
                            </h1>
                            <button
                                type="button"
                                className="btn-close"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                            />
                        </div>
                        <div className="modal-body">
                            <p>
                                Please read all our documents such as Privacy Policy, Cancellation
                                and Refund Policy and Terms and Conditions document before using our
                                mobile app and website.
                            </p>
                            <br />
                            <br />
                            <p>
                                This Customer agreement is an agreement between you (Customer, User
                                of this site) and HankersNest.com (Site,App, We, Us) that states the
                                terms and conditions under which you may use the Site and receive
                                HankersNest service, which includes delivery of product orders.
                            </p>
                            <br />
                            <br />
                            <p>
                                These Terms and Conditions and Privacy Policy are effective upon
                                acceptance in registration for new registering users, and are
                                otherwise effective from 11 Jan 2024 for all users. By
                                registering/signing up on the website you confirm that you have read
                                and agreed to these Terms and Conditions and the Privacy Policy. If
                                you do not agree to these Terms and Conditions and/or our Privacy
                                Policy, please do not register for or use HankersNest.com.
                            </p>
                            <br />
                            <br />
                            <p>
                                If you are under 18, you may use HankersNest.com only with
                                involvement of a parent or guardian.
                            </p>
                            <br />
                            <br />
                            <p>
                                <strong>
                                    1: Use of the HankersNest.com Site and HankersNest Mobile
                                    Application:
                                </strong>
                            </p>
                            <br />
                            <p>
                                Membership on the Website/application is free of charge for your
                                personal use subject to these Terms and Conditions and our Privacy
                                Policy. HankersNest does not charge any fee for browsing on the
                                Mobile APP. In particular, HankersNest may at its sole discretion
                                introduce new services and modify some or all of the existing
                                services offered on the Mobile APP. In such an event Mobile APP
                                reserves the right to introduce fees for the new services offered or
                                amend/introduce fees for existing services, as the case may be.
                                Changes to the Fee and Credit policies shall be posted on the Mobile
                                APP and such changes shall automatically become effective
                                immediately after they are posted on the Website. Unless otherwise
                                stated, all fees shall be quoted in Indian Rupees.
                            </p>
                            <br />
                            <p>
                                Non-payment: HankersNest reserves the right to issue a warning,
                                temporarily /indefinitely suspend or terminate your membership of
                                the Website and refuse to provide you with access to the Mobile APP
                                in case of non-payment of fees by you to HankersNest. HankersNest
                                also reserves the right to take legal action in case of non-payment
                                of fees by you to HankersNest. You shall not attempt to or
                                circumvent or manipulate the billing process or Fees owed to
                                HankersNest incorporated by way of reference in this Agreement.
                            </p>
                            <br />
                            <br />
                            <p>
                                <strong>Non-payment:</strong>
                                Taaza Food reserves the right to issue a warning, temporarily
                                /indefinitely suspend or terminate your membership of the Website
                                and refuse to provide you with access to the Mobile APP in case of
                                non-payment of fees by you to Taaza Food. Taaza Food also reserves
                                the right to take legal action in case of non-payment of fees by you
                                to Taaza Food. You shall not attempt to or circumvent or manipulate
                                the billing process or Fees owed to Taaza Food incorporated by way
                                of reference in this Agreement.
                            </p>
                            <br />
                            <br />
                            <p>
                                <strong>2. Your Account:</strong>
                            </p>
                            <br />
                            <p>
                                You agree to provide HankersNest with accurate and complete
                                information as required by registration for HankersNest Service
                                including, but not limited to, your legal name, billing and Shipping
                                addresses, telephone/mobile number. You will remain liable for any
                                use of the HankersNest Service until you notify HankersNest of the
                                unauthorized use, loss or theft.
                            </p>
                            <br />
                            <br />
                            <p>
                                <strong>3. Usage of the Mobile Number of the User:</strong>
                            </p>
                            <br />
                            <p>
                                HankersNest may send order confirmation &amp update the customer on
                                the order status and any further order information via SMS (short
                                messaging service) or call on the mobile/Landline number given by
                                the customer at the time of ordering. The customer hereby
                                unconditionally consents such intimation via SMS/Call by HankersNest
                                in accordance with the 'Do not disturb' guidelines of Telecom
                                Regulatory Authority of India (TRAI) or such other authority in
                                India and abroad
                            </p>
                            <br />
                            <br />
                            <p>
                                <strong>4. Arbitration:</strong>
                            </p>
                            <br />
                            <p>
                                If any dispute arises between you and HankersNest during your use of
                                the mobile app or thereafter, in connection with the validity,
                                interpretation, implementation or alleged breach of any provision of
                                the Customer Agreement, the dispute shall be referred to a sole
                                Arbitrator, who shall be an independent and neutral third party
                                identified by HankersNest. The place of arbitration shall be
                                Vadodara (Gujarat). The Courts at Vadodara shall have exclusive
                                jurisdiction in any proceedings arising out of this agreement.
                            </p>
                            <br />
                            <br />
                            <p>
                                The Arbitration &amp Conciliation Act, 1996, shall govern the
                                arbitration proceedings. The arbitration proceedings shall be in the
                                English language.
                            </p>
                            <br />
                            <br />
                            <p>
                                <strong>5. Indemnification:</strong>
                            </p>
                            <br />
                            <p>
                                You agree to defend, indemnify and hold HankersNest and its
                                Associates harmless from any claims and expenses, including
                                attorney's fees, arising in connection with a violation of this
                                Agreement by you or through use of your account. The limitations and
                                exclusions in this section apply to the maximum extent permitted by
                                applicable law.
                            </p>
                            <br />
                            <br />
                            <p>
                                <strong>6. Privacy:</strong>
                            </p>
                            <br />
                            <p>
                                Use of the Site is governed by our Privacy Policy, which can be
                                located at Privacy Policy section.
                            </p>
                            <br />
                            <p>
                                For any further questions about this Agreement or the Site please
                                email us on info@HankersNest.com.
                            </p>
                            <br />
                        </div>
                        <div className="modal-footer">
                            <button
                                type="button"
                                className="btn btn-secondary"
                                data-bs-dismiss="modal"
                            >
                                Close
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            {/* ======= Mobile nav toggle button ======= */}
            <i className="bi bi-list mobile-nav-toggle d-xl-none" onClick={handleNav} id='navhandler' />
            {/* ======= Header ======= */}
            <header id="header">
                <div className="d-flex flex-column">
                    <div className="profile">
                        {/* <a href="/"><img src="assets/img/profile-img.jpg" alt="website logo depicting birds building their nests" class="img-fluid rounded-circle"></a> */}
                        <h1 className="text-light mt-4">
                            <a href="/">Hanker’s Nest</a>
                        </h1>
                    </div>
                    <nav id="navbar" className="nav-menu navbar">
                        <ul>
                            {/* <li><a href="#hero" class="nav-link scrollto active"><i class="bx bx-home"></i> <span>Home</span></a></li> */}
                            <li>
                                <a href="#about" className="nav-link scrollto">
                                    <i className="bx bx-user" />
                                    <span>About Us</span>
                                </a>
                            </li>
                            <li>
                                <a href="#Web-development" className="nav-link scrollto">
                                    <i className="bx bx-laptop" />
                                    <span>Full Stack Web Developer</span>
                                </a>
                            </li>
                            <li>
                                <a href="#Robotics" className="nav-link scrollto">
                                    <i className="bx bxl-discord-alt" />
                                    <span>Robotics</span>
                                </a>
                            </li>
                            <li>
                                <a href="#courses" className="nav-link scrollto">
                                    <i className="bx bxs-offer" />
                                    <span>Courses Offered</span>
                                </a>
                            </li>
                            <li>
                                <a href="#facts" className="nav-link scrollto">
                                    <i className="bx bx-file-blank" />
                                    <span>Duration</span>
                                </a>
                            </li>
                            <li>
                                <a href="#skills" className="nav-link scrollto">
                                    <i className="bx bx-book-content" />
                                    <span>Skills</span>
                                </a>
                            </li>
                            <li>
                                <a href="#resume" className="nav-link scrollto">
                                    <i className="bx bx-server" />
                                    <span>Course Expectations</span>
                                </a>
                            </li>
                            {/* <li><a href="#jobexpectation" class="nav-link scrollto"><i class="bx bx-mail-send"></i> <span>Job
          Expectations</span></a></li> */}
                            <li>
                                <a href="#contact" className="nav-link scrollto">
                                    <i className="bx bx-envelope" />
                                    <span>Contact</span>
                                </a>
                            </li>
                        </ul>
                    </nav>
                    {/* .nav-menu */}
                </div>
            </header>
            {/* End Header */}
            <section
                id="hero"
                className="d-flex flex-column justify-content-center align-items-center"
            >
                <div className="hero-container" data-aos="fade-in">
                    <h1>Hanker’s Nest</h1>
                    <p>
                        <span
                            className="typed"
                            data-typed-items="Look for Excellence, Rest everything will follow."
                        />
                    </p>
                </div>
            </section>
            <main id="main">
                {/* ======= About Section ======= */}
                <section id="about" className="about">
                    <div className="container">
                        <div className="section-title">
                            <h2>About Us</h2>
                            <div className="first-para">
                                <p>
                                    We specialize in imparting comprehensive
                                    <strong>software education</strong>
                                    and training to students, equipping them with the skills to
                                    develop cutting-edge software solutions that
                                    <strong>meet industry standards</strong>. Recognizing a
                                    significant disparity between what traditional educational systems
                                    offer and the skills demanded by the dynamic software industry, we
                                    have dedicated ourselves to bridging this crucial gap.
                                </p>
                            </div>
                            <div className="other-para">
                                <p style={{ paddingTop: 10 }}>
                                    With over
                                    <strong>two decades of hands-on experience</strong>
                                    in the Software Industry, our team possesses a profound
                                    understanding of the intricacies and limitations of the current
                                    education system. We leverage this knowledge to design courses
                                    that not only address the deficiencies in traditional education
                                    but also ensure that our students are well-prepared and confident
                                    to meet the evolving demands of the industry.
                                </p>
                                <p style={{ paddingTop: 10 }}>
                                    Our curriculum spans a wide spectrum, covering FrontEnd, BackEnd,
                                    and Database technologies, ensuring a holistic understanding of
                                    the software development lifecycle. We go beyond theoretical
                                    concepts, providing
                                    <strong>practical, real-world applications</strong>
                                    to hone your skills and make you industry-ready.
                                </p>
                                <p style={{ paddingTop: 10 }}>
                                    In addition to classroom instruction, we offer opportunities to
                                    <strong>work on live final year projects</strong>. This hands-on
                                    experience in applying theoretical knowledge to practical
                                    scenarios is invaluable. It not only enhances your problem-solving
                                    skills but also gives you a taste of the challenges and
                                    intricacies of the software development process.
                                </p>
                                <p style={{ paddingTop: 10 }}>
                                    By choosing our training programs, you embark on a journey that
                                    goes beyond conventional learning. We don't just teach we empower
                                    you with the tools and expertise needed to thrive in the
                                    competitive landscape of the software industry. Join us, and let's
                                    shape the future of software development together.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="container-fluid">
                        <div className="row" id="Web-development">
                            <div
                                className="col-lg-4 row align-items-center"
                                data-aos="fade-right"
                            >
                                <img
                                    src="assets/img/computer1.png"
                                    className="img-fluid"
                                    alt="image depicting computer and logos of programming tools, symbolysing web development"
                                />
                            </div>
                            <div className="col-lg-8 pt-4 pt-lg-0 content" data-aos="fade-left">
                                <h3>Full Stack Web Developer</h3>
                                <p className="fst-italic">
                                    We intend to gain core expertise and skill in showcasing a
                                    institution online in the best possible manner. We are looking to
                                    share and develop expertise in the following domains.
                                </p>
                                <div className="d-none d-md-block">
                                    <div className="row">
                                        <div className="col-lg-4">
                                            <ul>
                                                <li>
                                                    <i className="bi bi-chevron-right" />
                                                    <span>HTML</span>
                                                </li>
                                                <li>
                                                    <i className="bi bi-chevron-right" />
                                                    <span>Css</span>
                                                </li>
                                                <li>
                                                    <i className="bi bi-chevron-right" />
                                                    <span>Javascript</span>
                                                </li>
                                                <li>
                                                    <i className="bi bi-chevron-right" />
                                                    <span>Jquery</span>
                                                </li>
                                                <li>
                                                    <i className="bi bi-chevron-right" />
                                                    <span>Ajax</span>
                                                </li>
                                            </ul>
                                        </div>
                                        <div className="col-lg-4">
                                            <ul>
                                                <li>
                                                    <i className="bi bi-chevron-right" />
                                                    <span>Bootstrap</span>
                                                </li>
                                                <li>
                                                    <i className="bi bi-chevron-right" />
                                                    <span>Node.js</span>
                                                </li>
                                                <li>
                                                    <i className="bi bi-chevron-right" />
                                                    <span>React JS</span>
                                                </li>
                                                <li>
                                                    <i className="bi bi-chevron-right" />
                                                    <span>TypeScript</span>
                                                </li>
                                            </ul>
                                        </div>
                                        <div className="col-lg-4">
                                            <ul>
                                                <li>
                                                    <i className="bi bi-chevron-right" />
                                                    <span>Next.js</span>
                                                </li>
                                                <li>
                                                    <i className="bi bi-chevron-right" />
                                                    <span>React Native</span>
                                                </li>
                                                <li>
                                                    <i className="bi bi-chevron-right" />
                                                    <span>MySql Server</span>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div className="d-md-none">
                                    <div className="row">
                                        <div className="col-6">
                                            <ul>
                                                <li>
                                                    <i className="bi bi-chevron-right" />
                                                    <span>HTML</span>
                                                </li>
                                                <li>
                                                    <i className="bi bi-chevron-right" />
                                                    <span>Css</span>
                                                </li>
                                                <li>
                                                    <i className="bi bi-chevron-right" />
                                                    <span>Javascript</span>
                                                </li>
                                                <li>
                                                    <i className="bi bi-chevron-right" />
                                                    <span>Jquery</span>
                                                </li>
                                                <li>
                                                    <i className="bi bi-chevron-right" />
                                                    <span>Ajax</span>
                                                </li>
                                                <li>
                                                    <i className="bi bi-chevron-right" />
                                                    <span>Bootstrap</span>
                                                </li>
                                                <li>
                                                    <i className="bi bi-chevron-right" />
                                                    <span>Node.js</span>
                                                </li>
                                            </ul>
                                        </div>
                                        <div className="col-6">
                                            <ul>
                                                <li>
                                                    <i className="bi bi-chevron-right" />
                                                    <span>React JS</span>
                                                </li>
                                                <li>
                                                    <i className="bi bi-chevron-right" />
                                                    <span>TypeScript</span>
                                                </li>
                                                <li>
                                                    <i className="bi bi-chevron-right" />
                                                    <span>Next.js</span>
                                                </li>
                                                <li>
                                                    <i className="bi bi-chevron-right" />
                                                    <span>React Native</span>
                                                </li>
                                                <li>
                                                    <i className="bi bi-chevron-right" />
                                                    <span>MySql Server</span>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <p>
                                    With these skills under their belt students will have acquired a
                                    comprehensive skill set and a strong foundation in both front-end
                                    and back-end technologies. They will be equipped to build and
                                    deploy full-fledged web applications from scratch.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="container-fluid">
                        <div className="row" id="Robotics">
                            <div className="col-lg-8 pt-4 pt-lg-0 content" data-aos="fade-right">
                                <h3>Robotics</h3>
                                <p className="fst-italic">
                                    We intend to gain core expertise and skill in showcasing a
                                    institution online in the best possible manner. We are looking to
                                    share and develop expertise in the following domains.
                                </p>
                                <div className="d-none d-md-block">
                                    <div className="row">
                                        <div className="col-12">
                                            <ul>
                                                <li>
                                                    <i className="bi bi-chevron-right" />
                                                    <span>IOT device Introduction</span>
                                                </li>
                                                <li>
                                                    <i className="bi bi-chevron-right" />
                                                    <span>Electricity basics and its application</span>
                                                </li>
                                                <li>
                                                    <i className="bi bi-chevron-right" />
                                                    <span>Basic electronics and components</span>
                                                </li>
                                                <li>
                                                    <i className="bi bi-chevron-right" />
                                                    <span>Programming with Arduino and sensors</span>
                                                </li>
                                                <li>
                                                    <i className="bi bi-chevron-right" />
                                                    <span>C++ for Arduino</span>
                                                </li>
                                                <li>
                                                    <i className="bi bi-chevron-right" />
                                                    <span>Transistors and intermediate circuits</span>
                                                </li>
                                                <li>
                                                    <i className="bi bi-chevron-right" />
                                                    <span>Real applications with Arduino</span>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <p>
                                    With these skills under their belt students will have acquired a
                                    comprehensive skill set and a strong foundation in both front-end
                                    and back-end technologies. They will be equipped to build and
                                    deploy full-fledged web applications from scratch.
                                </p>
                            </div>
                            <div className="col-lg-4 row align-items-center" data-aos="fade-left">
                                <img
                                    src="assets/img/car1.png"
                                    className="img-fluid"
                                    alt="bigger version of the website logo depicting birds building their nests"
                                />
                            </div>
                        </div>
                    </div>
                </section>
                {/* End About Section */}
                {/* <section id="courses" class="courses">
<div class="container ">
  <div class="section-title">
    <h2>Courses Offered</h2>
    <p>
      We offer a single Full fledged Course of <strong>Full Stack Developer </strong> with or without any software
      background.
      However, We have different packages for school going kids && for college going students as follows.
    </p>
  </div>

  <div class="container-fluid ps-0 ">
    <div class="row align-middle ">

      <div class="col-12 column ms-0" data-aos="fade-right">
        <div
          class="card gr-1 shadow-lg p-3 mb-5 ms-0 bg-body-tertiary rounded border border-info border-5 border-top-0 border-end-0 border-bottom-0">
          <div class="section-title">
            <h3 style="display:inline">For Kids </h3>
            <h4 style="display:inline"> 4th to 8th standard </h4>
            <h5>Maths + Software( Full Stack Development [4 years, 2 hours daily, 5 days a week] )</h5>
            <div class="pt-4">
              <h5 class="text-success pb-0">Mathematics</h5>
              <p class="pt-0">Students will be taught maths subject of current standard along with daily tables revision and
                periodic tests. </p>
            </div>
            <div class="pt-4">
              <h5 class="text-success pb-0">Computer Science: Full Stack Software Developer</h5>
              <p class="pt-0">Students who want to become software engineers can start as early as standard 4th. With around
                250 hours of coaching in a year, our students will be able to develop professional websites,
                ecommerce websites and professional Mobile applications by the end of 3rd or 4th year.</p>
            </div>
          </div>
          <a href="/software-course-for-kids.html">Read more...</a>
        </div>
      </div>

      <div class="col-12 column ms-0" data-aos="fade-left">
        <div
          class="card gr-1 shadow-lg p-3 mb-5 ms-0 bg-body-tertiary rounded border border-info border-5 border-top-0 border-end-0 border-bottom-0">
          <div class="section-title">
            <div>
              <h3 style="display:inline">For College Students </h3>
              <h5 class="ps-4">  Non-IT Students ( Studying BCom, BArt & others ) 2 years , 2 hours daily  </h5>
              <h5  class="ps-4">IT Students ( Studying BSc, MCA, B-Tech ) 1 years , 2 hours daily  </h5>
            </div>
            
            <div class="pt-4 content">
              <div class="d-none d-md-block">
                <div class="row ">
                  <div class="col-lg-4">
                    <ul>
                      <li><i class="bi bi-chevron-right"></i> <span>Training 95% practical</span></li>
                      <li><i class="bi bi-chevron-right"></i> <span>Coding best practices</span></li>
                      <li><i class="bi bi-chevron-right"></i> <span>5 Live projects</span></li>
                      <li><i class="bi bi-chevron-right"></i> <span>Resume preparation</span></li>
                    </ul>
                  </div>
                  <div class="col-lg-4">
                    <ul>
                      
                      <li><i class="bi bi-chevron-right"></i> <span>Portfolio development</span></li>
                      <li><i class="bi bi-chevron-right"></i> <span>Interview etticates</span></li>
                      <li><i class="bi bi-chevron-right"></i> <span>Interview Preparation</span></li>
                    </ul>
                  </div>
                  <div class="col-lg-4">
                    <ul>
                      
                      <li><i class="bi bi-chevron-right"></i> <span>Internship support</span></li>
                      <li><i class="bi bi-chevron-right"></i> <span>Company Research</span></li>
                      <li><i class="bi bi-chevron-right"></i> <span>Job Support</span></li>
                    </ul>
                  </div>

                </div>
              </div>
              <div class="d-md-none">
                <div class="row ">
                  <div class="col-6">
                    <ul>
                      <li><i class="bi bi-chevron-right"></i> <span>Training 95% practical</span></li>
                      <li><i class="bi bi-chevron-right"></i> <span>Coding best practices</span></li>
                      <li><i class="bi bi-chevron-right"></i> <span>5 Live Projects</span></li>
                      <li><i class="bi bi-chevron-right"></i> <span>Resume preparation</span></li>
                      <li><i class="bi bi-chevron-right"></i> <span>Portfolio development</span></li>
                    </ul>
                  </div>
                  <div class="col-6">
                    <ul>
                      <li><i class="bi bi-chevron-right"></i> <span>Interview etticates</span></li>
                      <li><i class="bi bi-chevron-right"></i> <span>Interview Preparation</span></li>
                      <li><i class="bi bi-chevron-right"></i> <span>Internship support</span></li>
                      <li><i class="bi bi-chevron-right"></i> <span>Company Research</span></li>
                      <li><i class="bi bi-chevron-right"></i> <span>Job Support </span></li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <a href="#">Read more...</a>
        </div>
      </div>
    </div>
  </div>




</div>
    </section> */}
                <section id="courses" className="courses pb-3">
                    <div className="container">
                        <div className="section-title">
                            <h2>Courses Offered</h2>
                            <p>
                                We offer a single Full fledged Course of
                                <strong>Full Stack Developer</strong>
                                with
                                <strong>Robotics</strong>. However, We have different packages for
                                school going kids.
                            </p>
                        </div>
                        <div className="container-fluid ps-0 p-0">
                            <div className="row align-middle">
                                <div className="col-12 col-md-4 ms-0 mb-0" data-aos="fade-right">
                                    <div className="card gr-1 shadow-lg p-3 mb-5 ms-0 bg-body-tertiary rounded border border-info border-5 border-start-0 border-end-0 border-bottom-0 mb-0">
                                        <div className="section-title11">
                                            <h3 className="text-center" style={{ color: "#C0C0C0" }}>
                                                Silver
                                            </h3>
                                            <h6
                                                className="text-center"
                                                style={{
                                                    fontFamily: "poppins, Arial, Helvetica, sans-serif"
                                                }}
                                            >
                                                <s>₹1500</s>
                                                ₹649/Month only
                                            </h6>
                                            <hr />
                                            <h6 className="text-primary">Curriculum Subjects</h6>
                                            <p style={{ paddingLeft: 10, marginBottom: 5 }}>
                                                1. Robotics
                                            </p>
                                            <p style={{ paddingLeft: 10 }}>2. Software Development</p>
                                            <h6 className="text-primary">Course Duration</h6>
                                            <p style={{ paddingLeft: 10, marginBottom: 5 }}>
                                                1 hours/Day
                                            </p>
                                            <p style={{ paddingLeft: 10 }}>3 days a week</p>
                                            <h6 className="text-primary">Timings</h6>
                                            <p style={{ paddingLeft: 10, marginBottom: 5 }}>7 - 8pm</p>
                                            <div className="float-end">
                                                {/* show in desktop */}
                                                <button
                                                    className=" d-none d-md-block btn btn-primary"
                                                    onClick={showalert}
                                                >
                                                    Buy Now
                                                </button>
                                                {/* show in mobile */}
                                                <button
                                                    className=" d-block d-md-none btn btn-primary"
                                                    onClick={() => openGpay('649')}
                                                >
                                                    Buy Now
                                                </button>
                                            </div>
                                            <p style={{ paddingLeft: 10 }}>
                                                <br />
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 col-md-4 ms-0" data-aos="fade-up">
                                    <div className="card gr-1 shadow-lg p-3 mb-5 ms-0 bg-body-tertiary rounded border border-info border-5 border-start-0 border-end-0 border-bottom-0">
                                        <div className="section-title11">
                                            <h3 className="text-center" style={{ color: "#FFD700" }}>
                                                Gold
                                            </h3>
                                            <h6
                                                className="text-center"
                                                style={{
                                                    fontFamily: "poppins, Arial, Helvetica, sans-serif"
                                                }}
                                            >
                                                <s>₹2500</s>
                                                ₹1249/Month only
                                            </h6>
                                            <hr />
                                            <h6 className="text-primary">Curriculum Subjects</h6>
                                            <p style={{ paddingLeft: 10, marginBottom: 5 }}>
                                                1. Robotics
                                            </p>
                                            <p style={{ paddingLeft: 10 }}>2. Software Development</p>
                                            <h6 className="text-primary">Course Duration</h6>
                                            <p style={{ paddingLeft: 10, marginBottom: 5 }}>
                                                2 hours/Day
                                            </p>
                                            <p style={{ paddingLeft: 10 }}>3 days a week</p>
                                            <h6 className="text-primary">Timings</h6>
                                            <p style={{ paddingLeft: 10, marginBottom: 5 }}>5 - 7pm</p>
                                            <div className="float-end">
                                                {/* show in desktop */}
                                                <button
                                                    className=" d-none d-md-block btn btn-primary"
                                                    onClick={showalert}                                                >
                                                    Buy Now
                                                </button>
                                                {/* show in mobile */}
                                                <button
                                                    className=" d-block d-md-none btn btn-primary"
                                                    onClick={() => openGpay('1249')}>
                                                    Buy Now
                                                </button>
                                            </div>
                                            <p style={{ paddingLeft: 10 }}>
                                                <br />
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 col-md-4 ms-0" data-aos="fade-left">
                                    <div className="card gr-1 shadow-lg p-3 mb-5 ms-0 bg-body-tertiary rounded border border-info border-5 border-start-0 border-end-0 border-bottom-0">
                                        <div className="section-title11">
                                            <h3 className="text-center" style={{ color: "#E5E4E2" }}>
                                                Platinum
                                            </h3>
                                            <h6
                                                className="text-center"
                                                style={{
                                                    fontFamily: "poppins, Arial, Helvetica, sans-serif"
                                                }}
                                            >
                                                <s>₹4000</s>
                                                ₹2499/Month only
                                            </h6>
                                            <hr />
                                            <h6 className="text-primary">Curriculum Subjects</h6>
                                            <p style={{ paddingLeft: 10, marginBottom: 5 }}>
                                                1. Robotics
                                            </p>
                                            <p style={{ paddingLeft: 10, marginBottom: 5 }}>
                                                2. Software Development
                                            </p>
                                            <p style={{ paddingLeft: 10 }}>3. Maths + Science</p>
                                            <h6 className="text-primary">Course Duration</h6>
                                            <p style={{ paddingLeft: 10, marginBottom: 5 }}>
                                                2 hours/Day
                                            </p>
                                            <p style={{ paddingLeft: 10 }}>5 days a week</p>
                                            <h6 className="text-primary">Timings</h6>
                                            <div className="float-end">
                                                {/* show in desktop */}
                                                <button
                                                    className=" d-none d-md-block btn btn-primary"
                                                    onClick={showalert}
                                                >
                                                    Buy Now
                                                </button>
                                                {/* show in mobile */}
                                                <button
                                                    className=" d-block d-md-none btn btn-primary"
                                                    onClick={() => openGpay('2499')}
                                                >
                                                    Buy Now
                                                </button>
                                            </div>
                                            <p style={{ paddingLeft: 10 }}>
                                                3 - 5pm
                                                <strong>/</strong>8 - 10am
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/* ======= Facts Section ======= */}
                <section id="facts" className="facts">
                    <div className="container">
                        <div className="section-title">
                            <h2>Duration</h2>
                            <p>
                                Every great thing was once nothing. It requires a certain amount of
                                time, effort and enery to achieve Desired results.
                            </p>
                        </div>
                        <div className="row no-gutters">
                            <div
                                className="col-lg-4 col-md-8 d-md-flex align-items-md-stretch"
                                data-aos="fade-up"
                            >
                                <div className="count-box">
                                    <i className="bi bi-emoji-smile" />
                                    <span
                                        data-purecounter-start={0}
                                        data-purecounter-end={700}
                                        data-purecounter-duration={1}
                                        className="purecounter"
                                    />
                                    <p>
                                        <strong>Learning</strong>
                                        <br />
                                        training required
                                    </p>
                                </div>
                            </div>
                            <div
                                className="col-lg-4 col-md-8 d-md-flex align-items-md-stretch"
                                data-aos="fade-up"
                                data-aos-delay={100}
                            >
                                <div className="count-box">
                                    <i className="bi bi-journal-richtext" />
                                    <span
                                        data-purecounter-start={0}
                                        data-purecounter-end={600}
                                        data-purecounter-duration={1}
                                        className="purecounter"
                                    />
                                    <p>
                                        <strong>Practice</strong>
                                        <br />
                                        working on different case studies
                                    </p>
                                </div>
                            </div>
                            {/* <div class="col-lg-3 col-md-6 d-md-flex align-items-md-stretch" data-aos="fade-up" data-aos-delay="200">
      <div class="count-box">
        <i class="bi bi-headset"></i>
        <span data-purecounter-start="0" data-purecounter-end="1453" data-purecounter-duration="1" class="purecounter"></span>
        <p><strong>Hours Of Support</strong> aut commodi quaerat</p>
      </div>
    </div> */}
                            <div
                                className="col-lg-4 col-md-8 d-md-flex align-items-md-stretch"
                                data-aos="fade-up"
                                data-aos-delay={300}
                            >
                                <div className="count-box">
                                    <i className="bi bi-people" />
                                    <span
                                        data-purecounter-start={0}
                                        data-purecounter-end={1200}
                                        data-purecounter-duration={1}
                                        className="purecounter"
                                    />
                                    <p>
                                        <strong>Internship</strong>
                                        <br />
                                        working on live projects
                                    </p>
                                </div>
                            </div>
                            <p>
                                700 Hours of Learning, 600 Hours of working on Project work and 1200
                                hours of internship is all it takes to become a Full Stack
                                Developer. It's important to remember that expertise is not solely
                                determined by the number of years spent in the field but also by the
                                quality and diversity of experiences during that time. Consistent
                                practice, working on challenging projects, seeking feedback, and
                                staying up-to-date with the latest advancements are all essential
                                for accelerating the learning process and achieving expertise. And
                                this is were our expertise is. We channelize students through
                                various through diverse set of experiences.
                            </p>
                            {/* <div class="row justify-content-center">
      <div class="col"> <button type="button" class="btn btn-primary mb-2">
          <span class="badge bg-white text-primary">3</span> Hours a Day
        </button> </div>
      <div class="col"> <button type="button" class="btn btn-primary mb-2">
          <span class="badge bg-white text-primary">5</span> Days a Week
        </button></div>
      <div class="col"> <button type="button" class="btn btn-primary mb-2">
          <span class="badge bg-white text-primary">11</span> Months a Year
        </button></div>
      <div class="col"> <button type="button" class="btn btn-primary mb-2">
          <span class="badge bg-white text-primary">2</span> Years
        </button></div>
    </div> */}
                        </div>
                    </div>
                </section>
                {/* End Facts Section */}
                {/* ======= Skills Section ======= */}
                <section id="skills" className="skills section-bg">
                    <div className="container">
                        <div className="section-title">
                            <h2>Skills</h2>
                            <p>
                                Each member in the team is required to practically work on each
                                topic. There is no theory but the learning is totally based on
                                practice. The more you practice, more expert you become. By the end
                                of the syllabus you can expect the student to have aquired the
                                following skill set.
                            </p>
                        </div>
                        <div className="row skills-content">
                            <div className="col-lg-6" data-aos="fade-up">
                                <div className="progress">
                                    <span className="skill">
                                        Responsive Website Designing
                                        <i className="val">80%</i>
                                    </span>
                                    <div className="progress-bar-wrap">
                                        <div
                                            className="progress-bar"
                                            role="progressbar"
                                            aria-valuenow={80}
                                            aria-valuemin={0}
                                            aria-valuemax={100}
                                        />
                                    </div>
                                </div>
                                <div className="progress">
                                    <span className="skill">
                                        Backend Programming
                                        <i className="val">80%</i>
                                    </span>
                                    <div className="progress-bar-wrap">
                                        <div
                                            className="progress-bar"
                                            role="progressbar"
                                            aria-valuenow={80}
                                            aria-valuemin={0}
                                            aria-valuemax={100}
                                        />
                                    </div>
                                </div>
                                <div className="progress">
                                    <span className="skill">
                                        Database Managment
                                        <i className="val">75%</i>
                                    </span>
                                    <div className="progress-bar-wrap">
                                        <div
                                            className="progress-bar"
                                            role="progressbar"
                                            aria-valuenow={75}
                                            aria-valuemin={0}
                                            aria-valuemax={100}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6" data-aos="fade-up" data-aos-delay={100}>
                                <div className="progress">
                                    <span className="skill">
                                        Mobile application Development
                                        <i className="val">80%</i>
                                    </span>
                                    <div className="progress-bar-wrap">
                                        <div
                                            className="progress-bar"
                                            role="progressbar"
                                            aria-valuenow={80}
                                            aria-valuemin={0}
                                            aria-valuemax={100}
                                        />
                                    </div>
                                </div>
                                <div className="progress">
                                    <span className="skill">
                                        Testing and Debugging an application
                                        <i className="val">90%</i>
                                    </span>
                                    <div className="progress-bar-wrap">
                                        <div
                                            className="progress-bar"
                                            role="progressbar"
                                            aria-valuenow={90}
                                            aria-valuemin={0}
                                            aria-valuemax={100}
                                        />
                                    </div>
                                </div>
                                <div className="progress">
                                    <span className="skill">
                                        Using AI tools in programming
                                        <i className="val">60%</i>
                                    </span>
                                    <div className="progress-bar-wrap">
                                        <div
                                            className="progress-bar"
                                            role="progressbar"
                                            aria-valuenow={60}
                                            aria-valuemin={0}
                                            aria-valuemax={100}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/* End Skills Section */}
                {/* ======= Resume Section ======= */}
                <section id="resume" className="resume">
                    <div className="container">
                        <div className="section-title">
                            <h2>Course Expectations</h2>
                            <p>
                                After Completing this course, you can expect yourself to be an
                                expert Full Stact Developer. You will single handedly be able to
                                design and develop a full application from scratch.
                            </p>
                        </div>
                        <div className="row">
                            <div className="col-lg-6" data-aos="fade-up">
                                <h3 className="resume-title">Font End Development</h3>
                                <div className="resume-item pb-0">
                                    <h4>Website Design</h4>
                                    <p>
                                        <em>
                                            A company's website gives the first and the most important
                                            impression about the company. Its the design and the layout
                                            that allows the user to quickly judge the company and their
                                            team. You can certainly contribute by creating a
                                        </em>
                                    </p>
                                    <ul>
                                        <li>Responsive Design</li>
                                        <li>Quick and Efficient Navigation</li>
                                        <li>Informative and User Friendly</li>
                                    </ul>
                                </div>
                                <h3 className="resume-title">Backend Development</h3>
                                <div className="resume-item">
                                    <h4>Programming</h4>
                                    <p>
                                        <em>
                                            Back-end development means working on server-side software,
                                            which focuses on everything you can’t see on a website.
                                            Back-end developers are required to have technical expertise,
                                            analytical thinking, and excellent collaboration skills. As a
                                            back-end web developer, you should be able to work
                                            independently to design the web infrastructure
                                        </em>
                                    </p>
                                    <ul>
                                        <li>Write high-quality code</li>
                                        <li>Perform quality assurance</li>
                                        <li>Assess efficiency and speed</li>
                                        <li>Troubleshoot and debug</li>
                                        <li>Train and support</li>
                                    </ul>
                                </div>
                                <div className="resume-item">
                                    <h4>Database</h4>
                                    <p>
                                        <em>
                                            Database Developers design the architecture for a web
                                            database, while Database Administrators help users submit and
                                            retrieve data using existing databases. Database Developers
                                            design and update the software tools that Database
                                            Administrators use to keep track of user interactions with the
                                            database
                                        </em>
                                    </p>
                                    <li>Create a scalable database structure</li>
                                    <li>Reduce redundancies</li>
                                    <li>Increase efficiencies in retrieving data</li>
                                </div>
                            </div>
                            <div className="col-lg-6" data-aos="fade-up" data-aos-delay={100}>
                                <h3 className="resume-title">Full Stack Development</h3>
                                <div className="resume-item">
                                    <h4>All in one Developer</h4>
                                    <p>
                                        <em>
                                            Full Stack Developers are all rounders. The develop both
                                        </em>
                                    </p>
                                    <ul>
                                        <li>
                                            A full stack developer plays a pivotal role in bridging the
                                            gap between the front-end and back-end development, ensuring
                                            seamless communication and collaboration across various
                                            aspects of a project.
                                        </li>
                                        <li>
                                            Their expertise allows them to understand the entire software
                                            development process, from user interface design and user
                                            experience to server-side scripting and database management,
                                            making them invaluable in creating robust, scalable, and
                                            efficient web applications.
                                        </li>
                                        <li>
                                            In essence, a full stack developer possesses the versatility
                                            and skills needed to bring a project from concept to
                                            completion, making them a cornerstone of modern software
                                            development teams.
                                        </li>
                                    </ul>
                                </div>
                                <div className="resume-item">
                                    <h4>Tools, Design and Delopment Methodologies</h4>
                                    <p>
                                        <em>
                                            Its not only the Software Development Languages but the
                                            methodologies, the design and the associated tools that makes
                                            a Complete Developer.
                                        </em>
                                    </p>
                                    <ul>
                                        <li>
                                            Object-Oriented Programming (OOP) is a programming paradigm
                                            that organizes data and behavior into reusable and
                                            self-contained objects, promoting modularity, flexibility, and
                                            code maintainability.
                                        </li>
                                        <li>
                                            Agile is a project management approach that emphasizes
                                            flexibility, collaboration, and customer satisfaction. It
                                            encourages iterative development, where software is built
                                            incrementally over multiple stages, allowing for regular
                                            reassessment and adaptation to changing requirements.
                                        </li>
                                        <li>Testing tools at various phases of developement</li>
                                        <li>Collaboration and managment tools</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/* End Resume Section */}
                {/* <section id="jobexpectation" class="jobexpectation">
<div class="container mb-0 pb-0">
  <div class="section-title mb-0 pb-0">
    <h2>
      Job Expectation:
    </h2>
    <p>
      You can expect the student to clear any interview for the position of a Full stack Developer in the salary
      range of Rs 3.6 to 6 Lakhs per annum.
    </p>

  </div>
</div>
</div>
    </section> */}
                {/* ======= Contact Section ======= */}
                <section id="contact" className="contact">
                    <div className="container">
                        <div className="section-title">
                            <h2>Contact Us</h2>
                            <p>
                                For any queries that you may have you can personally come and meet
                                us. Prior appointment will ensure that we can give sufficient time
                                to you. You can alternatively drop us an email or call us as per
                                your convenience.
                            </p>
                        </div>
                        <div className="row" data-aos="fade-in">
                            <div className="col-lg-5 d-flex align-items-stretch">
                                <div className="info">
                                    <div className="address">
                                        <i className="bi bi-geo-alt" />
                                        <h4>Location:</h4>
                                        <p>204, Dwarkesh Complex, Sun Pharma rd, Atladara 390012</p>
                                    </div>
                                    <div className="email">
                                        <i className="bi bi-envelope" />
                                        <h4>Email:</h4>
                                        <p>info@HankersNest.com</p>
                                    </div>
                                    <div className="phone">
                                        <i className="bi bi-phone" />
                                        <h4>Call:</h4>
                                        <p>
                                            <a href="tel:+918849171601">+91 8849171601</a>
                                        </p>
                                    </div>
                                    <iframe
                                        title='map'
                                        src="https://maps.google.com/maps?width=600&height=400&hl=en&q=dwarkesh complex, sun pharma road&t=&z=14&ie=UTF8&iwloc=B&output=embed"
                                        frameBorder={0}
                                        style={{ border: 0, width: "100%", height: 290 }}
                                        allowFullScreen="allowfullscreen"
                                    />
                                </div>
                            </div>
                            <div className="col-lg-7 mt-5 mt-lg-0 d-flex align-items-stretch">
                                <form id="contactForm" onSubmit={() => { return false }}>
                                    <div className="row">
                                        <div className="form-group col-md-6">
                                            <label htmlFor="name">Your Name</label>
                                            <input type="text" className="form-control" id="name" />
                                        </div>
                                        <div className="form-group col-md-6">
                                            <label htmlFor="name">Your Email</label>
                                            <input
                                                type="email"
                                                className="form-control"
                                                id="email"
                                                required="required"
                                            />
                                        </div>
                                    </div>
                                    <div className="form-group py-2">
                                        <label htmlFor="phone">Phone number</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="phone"
                                            required="required"
                                        />
                                    </div>
                                    <div className="form-group pb-2">
                                        <label htmlFor="name">Subject</label>
                                        <input type="text" className="form-control" id="subject" />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="message">Message</label>
                                        <textarea
                                            className="form-control"
                                            id="message"
                                            rows={10}
                                            required="required"
                                            defaultValue={""}
                                        />
                                    </div>
                                    {/* style */}
                                    <div className="my-3 text-center">
                                        <div id="dmsg" className="" style={{ height: 28 }} />
                                        <button
                                            className="btn btn-primary"
                                            type="submit"
                                            onClick={sendMsg}
                                            id="btn"
                                        >
                                            Send Message
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                    <div className="container d-md-none d-sm-flex justify-content-center mt-4">
                        <div className="row">
                            <a
                                className="col-sm-12 mb-2"
                                style={{
                                    fontSize: 18,
                                    borderRadius: 45,
                                    backgroundColor: "#4BB199",
                                    border: "none",
                                    color: "white"
                                }}
                                href="PrivacyPolicy.html"
                            >
                                Privacy Policy{" "}
                            </a>
                            <a
                                className="col-sm-12 mb-2"
                                type="button"
                                style={{
                                    fontSize: 18,
                                    borderRadius: 45,
                                    backgroundColor: "#4BB199",
                                    border: "none",
                                    color: "white"
                                }}
                                href="./CancellationReturnRefund.html"
                            >
                                Cancellation, Return &amp; Refunds Policy
                            </a>
                            <a
                                className="col-sm-12 mb-2"
                                type="button"
                                style={{
                                    fontSize: 18,
                                    borderRadius: 45,
                                    backgroundColor: "#4BB199",
                                    border: "none",
                                    color: "white"
                                }}
                                href="./TermsandConditions.html"
                            >
                                Terms &amp; Conditions
                            </a>
                        </div>
                    </div>
                    <div className="container d-none d-md-flex justify-content-center mt-4">
                        <Link
                            className="mx-2 px-4"
                            style={{
                                fontSize: 18,
                                borderRadius: 45,
                                backgroundColor: "#4BB199",
                                border: "none",
                                color: "white"
                            }}
                            href="./PrivacyPolicy.html"
                        >
                            Privacy Policy
                        </Link>
                        <Link
                            className="mx-2 px-4"
                            type="button"
                            style={{
                                fontSize: 18,
                                borderRadius: 45,
                                backgroundColor: "#4BB199",
                                border: "none",
                                color: "white"
                            }}
                            href="./CancellationReturnRefund.html"
                        >
                            Cancellation, Return &amp; Refunds Policy
                        </Link>
                        <Link
                            className="mx-2 px-4"
                            type="button"
                            style={{
                                fontSize: 18,
                                borderRadius: 45,
                                backgroundColor: "#4BB199",
                                border: "none",
                                color: "white"
                            }}
                            href="./TermsandConditions.html"
                        >
                            Terms &amp; Conditions
                        </Link>
                    </div>
                </section>
            </main>

            <a href="#" className="back-to-top d-flex align-items-center justify-content-center" >
                <i className="bi bi-arrow-up-short"></i>
            </a>
        </>
    )
}