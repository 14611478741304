import React, { useEffect } from 'react'
import { useLocation } from 'react-router'

export default function PaymentGateway() {
  const location = useLocation();
  const { url } = location.state || {}

  useEffect(() => {

    //let url = window.location.href
    


    window.location.href = url
    // eslint-disable-next-line
  }, [])

  return (
    <></>
  )
}
