import React, { useEffect,useState } from 'react'
import {useNavigate} from 'react-router-dom'

export default function Pay() {
    const Navigate = useNavigate();
    const burlb = (process.env.REACT_APP_TYPE === '1') ?  process.env.REACT_APP_BACKEND_SERVER_URL : process.env.REACT_APP_BACKEND_TESTING_URL
    const [err1, setErr1] = useState('')


    useEffect(() => {
        const url = window.location.href
        const querystrings = url.split('?')[1]
        console.log(querystrings);
        console.log((querystrings.split('=')[1]))
        const amt = querystrings.split('=')[1]
        console.log(amt)
        const transactionid = querystrings.split('=')[2]
        const TransactionIdValue = transactionid.split('&')[0]
        const amtVal = amt.split('&')[0]
        const curl = querystrings.split('=')[3] + "?transid=" + TransactionIdValue
        console.log(curl)
        console.log(TransactionIdValue)
        console.log(amtVal);
        console.log(querystrings.split('='))
        const pay = async () => {
            const bod = {
              user_id: '1',
              phone: '999999999',
              name: 'PRAMOD',
              email: 'pramodkrao@gmail.com',
              tempId: '11',
              price: amtVal,
              rurl: `https://hankersnest.com/PaymentStatusPsy?curl=${curl}`
            }
            const response = await fetch(`${burlb}/api/requestPayment`, {
              method: 'POST',
              mode: 'cors',
              headers: {
                'Content-Type': 'application/json',
              },
              body: JSON.stringify(bod)
            })
        
            const data = await response.json()
            console.log("data is ", data)
        
            if (response.status === 201) {
              Navigate('/pg', { state: { url: data.url } })
            }else{
              setErr1(JSON.stringify(data))
            }
        
        
          }
          pay()
    })
    return (
    <></>
    )
}
