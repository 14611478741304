import React, { useEffect, useState } from 'react'

export default function PaymentStatus() {

  const [paymentStatus, setPaymentStatus] = useState('')
  const burlb = (process.env.REACT_APP_TYPE === '1') ?  process.env.REACT_APP_BACKEND_SERVER_URL : process.env.REACT_APP_BACKEND_TESTING_URL
  const burlf = (process.env.REACT_APP_TYPE === '1') ?  process.env.REACT_APP_FRONTEND_SERVER_URL : process.env.REACT_APP_FRONTEND_TESTING_URL

  useEffect(() => {
    const fetchData = async () => {
      const url = window.location.href
      console.log("url is :", url);
      const params = url.split('?')[1]
      const tidValue = params.split('=')[1]
      console.log(tidValue);
      const response = await fetch(`${burlb}/api/status/${tidValue}`, {
        method: 'POST',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json'
        }
      })
      const data = await response.json()
      // setPaymentStatus(data.status)
      console.log(data)
      setPaymentStatus(JSON.stringify(data))
    }
    fetchData()
    // eslint-disable-next-line
  }, [])

  return (
    <>{paymentStatus}</>
  )
}