import React, { useEffect, useState } from 'react'

export default function PaymentStatusPsy() {

  const [paymentStatus, setPaymentStatus] = useState('')
  const burlb = (process.env.REACT_APP_TYPE === '1') ?  process.env.REACT_APP_BACKEND_SERVER_URL : process.env.REACT_APP_BACKEND_TESTING_URL
  const burlf = (process.env.REACT_APP_TYPE === '1') ?  process.env.REACT_APP_FRONTEND_SERVER_URL : process.env.REACT_APP_FRONTEND_TESTING_URL

  useEffect(() => {
    const fetchData = async () => {
      const url = window.location.href

      // url =  https://hankersnest.com/PaymentStatusPsy?curl=http://psy.hankersnest.com/PaymentSuccessfull?transid=1722082860905&tid=1722082862320

      console.log("url is :", url);
      const params = url.split('?')
      const tid = params[2]
      console.log(tid)
      const tidValue = tid.split('=')[1]
      const curl = params[1]
      const curlSubVal = curl.split('=')[1] + "?" + tid
      const curlVal = curlSubVal.split('&')[0]
      console.log(tidValue);
      console.log(curlVal)
      
      const response = await fetch(`${burlb}/api/status/${tidValue}`, {
        method: 'POST',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json'
        }
      })
      const data = await response.json()
      // setPaymentStatus(data.status)
      console.log()
      setPaymentStatus(JSON.stringify(data))
      window.location.href = curlVal
    }
    fetchData()
    // eslint-disable-next-line
  }, [])

  return (
    <>{paymentStatus}</>
  )
}