import { useState } from 'react'
import { useNavigate } from 'react-router'

function App() {
  // eslint-disable-next-line
  const burlb = (process.env.REACT_APP_TYPE === '1') ?  process.env.REACT_APP_BACKEND_SERVER_URL : process.env.REACT_APP_BACKEND_TESTING_URL
  const burlf = (process.env.REACT_APP_TYPE === '1') ?  process.env.REACT_APP_FRONTEND_SERVER_URL : process.env.REACT_APP_FRONTEND_TESTING_URL
  const [val, setVal] = useState(1)
  const Navigate = useNavigate()
  const [err1, setErr1] = useState('')

  const pay = async () => {
    const bod = {
      user_id: '1',
      phone: '999999999',
      name: 'PRAMOD',
      email: 'pramodkrao@gmail.com',
      tempId: '11',
      price: val,
      rurl: 'https://hankersnest.com/PaymentStatus'
    }
    const response = await fetch(`${burlb}/api/requestPayment`, {
      method: 'POST',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(bod)
    })

    const data = await response.json()
    console.log("data is ", data)

    if (response.status === 201) {
      Navigate('/pg', { state: { url: data.url } })
    }else{
      setErr1(JSON.stringify(data))
    }


  }


  return (
    <>
    {(err1 === '')?
    <div className="App container-fluid">
      <div className="card" style={{ width: 400 }}>
        <div className="card-header">
          Featured
        </div>
        <div className="card-body">
          <h5 className="card-title text-start" >Special title treatment</h5>
          <div className="input-group mb-3">
            <span className="input-group-text">$</span>
            <input type="number" className="form-control" value={val} aria-label="Amount (to the nearest dollar)" />
            <span className="input-group-text">.00</span>
          </div>
          <p className="card-text text-start">With supporting text below as a natural lead-in to additional content.</p>
          <button href="#" className="btn btn-primary" onClick={pay}>Pay</button>
        </div>
      </div>
    </div>
    :  <div> {err1} </div> }
    </>
  )
}

export default App
