import React from 'react'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import PaymentGateway from './PaymentGateway'
import App from './App'
import Hankersnest from './Hankersnest'
import Home from './Home'
import PaymentStatus from './PaymentStatus'
import Pay from './Pay'
import PaymentStatusPsy from './PaymentStatusPsy'

export default function Main() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path='/pg' element={<PaymentGateway />}></Route>
        <Route path='/success' element={<App />}></Route>
        <Route path='/' element={<Home/>}></Route>
        <Route path='/hankersnest' element={<Hankersnest />}></Route>
        <Route path='/PaymentStatus' element={<PaymentStatus />}></Route>
        <Route path='/PaymentStatusPsy' element={<PaymentStatusPsy />}></Route>
        <Route path='/Pay' element={<Pay />}></Route>
      </Routes>
    </BrowserRouter>
  )
}
