import React, { useEffect } from 'react'

const burlb = (process.env.REACT_APP_TYPE === '1') ?  process.env.REACT_APP_BACKEND_SERVER_URL : process.env.REACT_APP_BACKEND_TESTING_URL
const burlf = (process.env.REACT_APP_TYPE === '1') ?  process.env.REACT_APP_FRONTEND_SERVER_URL : process.env.REACT_APP_FRONTEND_TESTING_URL

export default function Home() {

  useEffect(() => {
    window.location.href = `${burlf}/home.html`
    // eslint-disable-next-line
  }, [])

  return (
    <></>
  )
}
